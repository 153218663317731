export const ReCaptchaKey = "6LdNRhsnAAAAAN5hhGaTSIg91Id8uiSoRgRZlxwW";

export const devRecaptcha = "6LeRHN4nAAAAAPR1idyGxICABpy-C_M9ukobe0Bp"; // sitekeys

/// Developers recaptcha key
// export const devRecaptcha = "AIzaSyBCsuDmupf-aS6Gsz1qaTaWqna3TtYbv1U"; // sukhvinder
// export const devRecaptcha = "AIzaSyBSk8f6xuhxBxabSZwp7lRKl-nuhDiZm3A";



// export const devRecaptcha = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";